//
//  Video
//  -----------------------------------------

.section-video {
    position: relative;
    z-index: $zindex-full-video;
    width: 100%;
    padding: 20px 0;
    margin-top: 63px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin: 0;
    height: 75vh;
    overflow: hidden;
    max-height: 100vh;
    background-color: $zby-gray-darker;
    background: linear-gradient($zby-gray-darker, darken($zby-gray-darker, 5%));
    min-height: 500px;

    @include breakpoint($screen-md) {
        margin: 0;
        height: 80vh;
        max-height: 600px;
    }
}

.video-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-position: center center;
}

.bg-gradient {
    display: none;
}

.main-copy {
    display: block;
    flex: 0 1 auto;
    margin: auto auto;
    height: auto;
    padding: 0 0;
    text-align: left;
    max-width: 960px;

    @include breakpoint($screen-md) {
        padding: 0 15px;
    }

    h1 {
        margin: 0 0 0.5em;
        font-size: 2.1em;
        font-weight: 400;
        line-height: 1.1;
        letter-spacing: -0.6px;
        color: white;
        width: 100%;
        display: block;

        @include breakpoint($screen-md) {
            margin: 0 0 0.1em;
            font-size: 2.8em;
            font-weight: 400;

            span {
                display: inline-block;
                clear: both;
            }
        }
    }

    p {
        margin: 0;
        font-size: 1.3em;
        font-weight: 400;
        line-height: 1.3;
        color: white;

        @include breakpoint($screen-md) {
            font-size: 1.8em;
            letter-spacing: 1px;
            font-weight: 400;
        }
    }
}

.button-cta {
    position: relative;
    padding: 0 0 1.2em;
    text-align: center;

    @include breakpoint($screen-md) {
        padding: 0 20px 20px;
    }
}

.content-wrap {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;top:0;
    opacity: 1;
    transition: all 200ms ease-in-out;
    transform: translate(0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.ng-animate {
        // transition-duration: 20s;
    }

    &.ng-hide-remove {
        // border: 10px solid orange;
        opacity: 0;
        // transform: scale(1.05, 1.05);
        transition: all 250ms ease-in-out 150ms;
    }

    &.ng-hide-remove-active {
        // border: 10px solid red;
    }

    &.ng-hide-add {
        // border: 10px solid green;
    }

    &.ng-hide-add-active {
        // border: 10px solid yellow;
    }

    &.ng-hide {
        opacity: 0;
    }

    @include breakpoint($screen-md) {
        margin-top: -130%;
        // top: 65.8px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        padding-bottom: 0;
    }
}

.main-copy-container {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    padding: 0 15px;

    @include breakpoint($screen-md) {
        max-width: 100%;
        margin-top: 2em;
        background: transparent;
    }
}

.home-buttons-container {
    position: relative;
    width: 100%;
    margin: 20px 0 0;
    padding: 15px 0;

    @include breakpoint($screen-md) {
        margin-top: 2em;
        background: transparent;
    }
}

.home-buttons {
    width: 100%;
    margin: auto auto;
    text-align: center;

    @include breakpoint($screen-md) {
        display: inline-block;
    }

    .btn {
        min-width: 160px;
        margin: 0 15px 15px;
        display: block;
        margin-bottom: 12px;

        @include breakpoint($screen-md) {
            display: inline-block;
            margin: 0 15px 0 0;
        }
    }
}

@keyframes fadeInPoster {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.poster-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $zby-gray-darker;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0;
    animation: 150ms fadeInPoster ease-in-out forwards;    

    &.open {
        filter: blur(8px);
        transform: scale(1.1, 1.1);
    }

    &.poster-image-customer {
        background-image: url('[base]/assets/video/customer-video-2x.jpg');
        @include breakpoint($screen-sm) {
            background-image: url('[base]/assets/video/customer-video.jpg');
        }
    }

    &.poster-image-retailer {
        background-image: url('[base]/assets/video/retailer-video-2x.jpg');
        @include breakpoint($screen-sm) {
            background-image: url('[base]/assets/video/retailer-video.jpg');
        }
    }

    &.ng-animate {
        transition: transform 150ms ease-in-out;
    }
}

.poster-image-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.easyhtml5video {
    display: none;
    @include breakpoint($screen-md) {
        display: block;
    }

    video {
        opacity: 0;
        transition: all 150ms ease;

     &.loaded {
        opacity: 1;
        transition: all 0s ease 150ms;
     }
    }
}