//  
//  Login page
//  -----------------------------------------

@keyframes fadeInSpinner {
	0% {
		opacity: 0;
		transform: scale(2, 2);
	}

	100% {
		opacity: 1;
		transform: scale(1,1);
	}
}

.page-login {
	background: $zby-background;
	position: relative;

	.site-footer {
		background: white;
	}

}

.login-container {
	position: relative;
	border-radius: 4px;
	overflow: hidden;

	@include breakpoint($screen-sm) {
		// margin: 2em auto 10em;
	}

	iframe {
		position: relative;
		width: 100%; 
		height: 800px;

		@include breakpoint($screen-lg) {
			height: 80vh;
			min-height: 800px;
		}
	}
}

.iframe-container {
	opacity: 0;
	transition: all 400ms ease;
	transform: translate(0, 15px);

	&.done {
		opacity: 1;
		transition: all 400ms ease;
		transform: translate(0, 0);
	}
}

.loading-container {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	z-index: 0;
	opacity: 0;
	animation: fadeInSpinner 250ms ease forwards;
	justify-content: center;
	align-items: center;
	margin-top: -100px;

	@include breakpoint($screen-sm) {
		justify-content: center;
		align-items: center;
		margin-top: auto;
	}
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}
 
@-webkit-keyframes spinner {
    to {-webkit-transform: rotate(360deg);}
}
 
.loading-spinner {
    min-width: 30px;
    min-height: 30px;
    flex: 0 0 100px;
	height: 100px;
	width: 100px;
	margin: 5em 0;
	transform: translate(0, -200px);
	position: relative;
}
 
.loading-spinner:before {
    content: 'Loading…';
    position: absolute;
    top: 30%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
}
 
.loading-spinner:not(:required):before {
    content: '';
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: transparent;
    animation: spinner .6s linear infinite;
    -webkit-animation: spinner .6s linear infinite;
}