//  
//  Grid
//  -----------------------------------------

$max-width: 960px;
$total-columns: 12;
$column-width: 100 / $total-columns;
$gutter: 15px;

// Main grid settings
$grid: ( 
	xs: false, 
	small: $screen-sm, 
	medium: $screen-md, 
	large: $screen-lg, 
	xl: $screen-xl
);

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    @include breakpoint($screen-sm) {
        max-width: $screen-sm - 30px;
    }

    @include breakpoint($screen-md) {
        max-width: $screen-md - 30px;
    }

    @include breakpoint($screen-lg) {
        max-width: $screen-lg;
    }

}

.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // &::before {
    //     content: ' ';
    //     display: table;
    // }

    // &::after {
    //     content: ' ';
    //     display: table;
    //     clear: both;
    // }

    .row {
        max-width: none;
        margin-left: -15px;
        margin-right: -15px;
    }
}

@mixin media-query-wrapper($size: false) {
    @if $size !=false {
        // if there's a break, wrap in media query
        @include breakpoint($size) {
            @content
        }
    }
    @else {
        // just show content
        @content
    }
}

[class^="xs"],
[class^="small"],
[class^="medium"],
[class^="large"],
[class^="xl"] {
    padding: 0 $gutter;
}

// Main grid mixin
@mixin create-grid($grid) {

    // Cycle through sizes and generate grid
    @each $name, $size in $grid {

        // conditionally wrap in media query
        @include media-query-wrapper($size) {
            @for $i from 0 through $total-columns {
                $width: $column-width * $i;
                .#{$name}-#{$i} {
                    flex: 0 0 $width + %;
                    max-width: $width + %;
                }
                .#{$name}-push-#{$i} {
                    position: relative;
                    left: $width + %;
                }
                .#{$name}-pull-#{$i} {
                    position: relative;
                    left: -($width) + %;
                }
                .#{$name}-offset-#{$i} {
                    margin-left: $width + %;
                }
                .#{$name}-order-#{$i} {
                    order: $i;
                }
            }
        }
    }
}

@mixin column($lg: 12, $medium: 12, $small: 12, $xs: 12) {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: $gutter;
    padding-right: $gutter;
    flex-basis: (100% / $total-columns) * $xs;
    box-sizing: border-box;

    // This fixes layout on IE and allows use of the same mixin for float and flex grids
    width: 100%;
    max-width: (100% / $total-columns) * $xs;

    @include breakpoint($screen-sm) {
        flex-basis: (100% / $total-columns) * $small;
        max-width: (100% / $total-columns) * $small;
    }

    @include breakpoint($screen-md) {
        flex-basis: (100% / $total-columns) * $medium;
        max-width: (100% / $total-columns) * $medium;
    }

    @include breakpoint($screen-lg) {
        flex-basis: (100% / $total-columns) * $lg;
        max-width: (100% / $total-columns) * $lg;
    }
}

@mixin columns-before($lg: 12, $medium: 12, $small: 12, $xs: 12) {
  margin-left: (100% / 12) * $xs;

  @include breakpoint($screen-sm) {
    margin-left: (100% / 12) * $small;
  }

  @include breakpoint($screen-md) {
    margin-left: (100% / 12) * $medium;
  }

  @include breakpoint($screen-lg) {
    margin-left: (100% / 12) * $lg;
  }
}

@mixin columns-after($lg: 12, $medium: 12, $small: 12, $xs: 12) {
  margin-right: (100% / 12) * $xs;

  @include breakpoint($screen-sm) {
    margin-right: (100% / 12) * $small;
  }

  @include breakpoint($screen-md) {
    margin-right: (100% / 12) * $medium;
  }

  @include breakpoint($screen-lg) {
    margin-right: (100% / 12) * $lg;
  }
}

@mixin order($lg: null, $medium: null, $small: null, $xs: null) {
    @if $xs != null {
        order: $xs;
    }

    @if $small != null {
        @include breakpoint($screen-sm) {
            order: $small;
          }
    }
    @if $medium != null {
        @include breakpoint($screen-md) {
            order: $medium;
          }
    }
    @if $lg != null {
        @include breakpoint($screen-lg) {
            order: $lg;
          }
    }
}


// Run mixins 
@include create-grid($grid);

