//  
//  Validation
//  -----------------------------------------

form.ng-submitted {
	.form-control {
		&.ng-invalid {
		  border-color: mix($zby-red, $input-border-color, 40%);
		}
	}
}