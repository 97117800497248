//  
//  How it works
//  -----------------------------------------

.section-how-it-works {
	$section-bg: #F4F4F4;
	background: linear-gradient($section-bg, darken($section-bg, 2%));
	
	@include breakpoint($screen-sm) {
		background: transparent;
	}
}

.how-it-works {
	width: 100%;
	h2 {
		margin: 0 0 0.5em;
		line-height: 1;
	}
	p {
		margin: 0 0 1em;
	}
}

.how-it-works-content {
	clear: both;
	width: 100%;
	display: block;
	

	@include breakpoint($screen-sm) {
		display: flex;
	}

	.iphone {
		flex: 0 1 32%;
		padding: 0;
		display: none;
		

		@include breakpoint($screen-sm) { 
			display: block;
		}

		@include breakpoint($screen-md) {
			flex: 0 1 32%;
			display: block;
			padding: 0 29px;
		}

		img {
			width: 100%;
		}
	}
	.person {
		flex: 1 1 67.1361502%;
		display: none;
		
		
		@include breakpoint($screen-md) {
			flex: 1 1 67.1361502%;
		}

		@include breakpoint($screen-md) {
			padding-left: 0;
			display: block;
		}

		img {
			
		}
	}
}

.iphone {
	margin-left: 0;

	@include breakpoint($screen-md) {
		// margin-left: 4em;
	}
}

.hand {
	display: none;

	@include breakpoint($screen-lg) {
		display: block;
	}
}

.iphone-phone {
	position: relative;
	background: rgb(244, 244, 244);
	width: 100%;
	height: 0;
	padding: 0 0 percentage(539/266);

	@include breakpoint($screen-sm) {
		border-radius: 2em;
	}

	@include breakpoint($screen-md) {
		border-radius: 1.4em;
	}

	@include breakpoint($screen-lg) {
		border-radius: 2em;
	}
}

.iphone-header, .iphone-footer {
	height: 0;
	width: 100%;
	padding: 0 0 percentage(63/266);
}

.iphone-header {
	position:relative; 

	&:after {
		content: " ";
		position: absolute;
		left: 35.8490566%;
		margin-left: -3px;
		right: 0;
		top: percentage(66/128);
		bottom: 0;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #C2D0CF;
	}

	&:before {
		content: " ";
		position: absolute;
		left: percentage(240/539);
		right: 0;
		margin-top: -1px;
		top: percentage(74/128);
		bottom: 0;
		width: percentage(41/267);
		height: percentage(10/128);
		border-radius: 3px;
		background: #C2D0CF;
	}
}

.iphone-content-wrapper {
	padding: 0 6.5%;
	width: 100%;
}

.iphone-content {
	background: white;
	height: 0;
	margin: 0 auto;
	width: 100%;
	padding: 0 0 percentage(410/233);
	position: relative;
	border: 1px solid rgb(235, 235, 235);
	border-radius: 2px;
	overflow: hidden;
}

.person-content-carousel {
	width: 100%;
	position: relative;

	.carousel-slide {
		position: relative;
		width: 100%;
		display: none;

		@include breakpoint($screen-sm) {
			display: block;
			padding-left: 30px;
		}

		@include breakpoint($screen-lg) {
			position: absolute;
		}

		img {
			width: 100%;
		}

		&.ng-hide {
			opacity: 0;
			transform: translate(50px, 0) translateZ(0);
		}

		&.ng-hide-add {
			transition: opacity 200ms ease, transform 400ms ease;
		}

		&.ng-hide-remove {
			transform: translateZ(0);
			transition: opacity 200ms ease 200ms;
			position: absolute;
		}

		&.ng-hide-add,
		&.ng-hide-remove {
			
		}
	}
}

.iphone-content-carousel {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;

	.carousel-slide {
		position: absolute;
		width: 100%;

		&.ng-hide {
			transform: scale(0.95, 0.95);
			opacity: 0;
		}

		&.ng-hide-add,
		&.ng-hide-remove {
			transition: opacity 200ms ease, transform 400ms ease;
		}
	}
}

.svg-fingers-left-wrapper {
	position: absolute;
	z-index: 1;
	left: 0;
	margin-top: 47%;
	width: 75px;
	left: -110px;

	.svg-fingers-left {
		position: relative;
		height: 0;
		width: 100%;
		padding: 0 0 percentage(134.44/51.16);

		svg {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

.svg-fingers-bottom-wrapper {
	position: absolute;
	z-index: 1;
	left: 0;
	margin-left: -70px;
	margin-top: 85%;
	width: 55px;
	z-index: -1;

	.svg-fingers-bottom {
		position: relative;
		height: 0;
		width: 100%;
		padding: 0 0 percentage(185/42);

		svg {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

.svg-fingers-right-wrapper {
	position: absolute;
	z-index: 1;
	height: 38.5899814%;
	margin-top: 40%;
	margin-left: 74%;
	
	width: 85px;

	.svg-fingers-right {
		position: relative;
		height: 0;
		width: 100%;
		padding: 0 0 percentage(209/64);

		svg {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

.nav-tabs {
	border-radius: 10px;
	width: 100%;

     @include breakpoint($screen-sm) {
        display: flex;
        margin: 0 0 3em;
        border-radius: 0;
        overflow: visible;
    }

    li {
        flex: 1 1 100%;
        width: 100%;
        padding: 25px 30px;
        text-align: center;
        list-style: none;
        cursor: default;
        background: #fefefe;
        border: 1px solid #ddd;
        display: block;
        border-radius: 0;
        margin: 0;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        display: flex;
        border-color: #dedede;

        @include breakpoint($screen-sm) {
        	padding: 1em;
            border-radius: 8px;
            margin: 0.6em;
            cursor: pointer;
        }

        &:first-child {
            margin-left: 0;
            border-bottom-width: 0;
            border-radius: 8px 8px 0 0;
            border-color: #eee #ddd #ddd #ddd;
        }

        &:last-child {
            margin-right: 0;
            border-top-width: 0;
            border-radius: 0 0 8px 8px;
            border-color: #ddd #ddd #ccc #ddd;
        }

        @include breakpoint($screen-sm) {
        	&:first-child {
	            border-bottom-width: 1px;
	            border-top-width: 1px;
	            border-radius: 8px;
	            border-color: #dedede;
	        }

	        &:last-child {
	        	border-bottom-width: 1px;
	            border-top-width: 1px;
	            border-radius: 8px;
	            border-color: #dedede;
	        }
        }

        background: white;
        
        
        h3 {
            color: $zby-blue-dark;
            line-height: 1;
            margin: 3px 0 6px;
            font-weight: 500;
            font-size: 26px;
            display: block;
            text-align: left;

            span {
            	display: none;
            }
        }
        
        p {
        	font-family: $font-family-sans-serif;
        	font-weight: 500;
            color: $zby-blue-dark;
            margin: 0;
            font-size: 15px;
            display: block;
            text-align: left;
            line-height: 1;
        }

		// Text on li
        @include breakpoint($screen-sm) {
			h3 {
	            margin: 0 0 0.3em;
	            font-size: 1.5em;
	            line-height: 1;
	            color: $zby-gray-light;
	            text-align: center;

	            span {
	            	display: inline;
	            }
	        }

	        p {
	            margin: 0;
	            font-size: 1em;
	            line-height: 1;
	            color: $zby-gray-light;
	            text-align: center;
	        }

	        &:hover {
	            border-color: $zby-blue-dark;
	        }

	        &.active {
	            background: white;
	            border-color: $zby-blue-dark;
	            
	            h3 {
	                color: $zby-blue-dark;
	            }
	            
	            p {
	                color: $zby-blue-dark;
	            }
	        }
        }
    }
}

.nav-tab-content {
	flex: 1 1 100%;
}

.nav-tab-icon {
	flex: 1 1 64px;
	margin-right: 15px;

	@include breakpoint($screen-sm) {
		display: none;
	}
}

.svg-wrapper-how-it-works-1,
.svg-wrapper-how-it-works-2,
.svg-wrapper-how-it-works-3 {
	width: 100%;

	.svg {
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}


.svg-wrapper-check-small,
.svg-wrapper-plus-small,
.svg-wrapper-clock-small {
	width: 100%;
	position: relative;
	height: 0;
	padding: 0 0 100%;

	.svg {
		padding: 0 5px 0 0;
		width: 100%;
		height: 100%;
		position: absolute;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}








