//  
//  Intro - Retailer
//  -----------------------------------------

$section-bg: #F4F4F4;

.section-intro-retailer-title,
.section-intro-customer-title {
	padding: 2em 0;

	@include breakpoint($screen-sm) {
		padding: 1.7em 0;
	}

	h2, h3, p {
		color: white;
	}

	h2 {
		margin: 0;
		font-size: 1.3rem;
		max-width: 95%;
		margin: 0 auto;

		@include breakpoint($screen-sm) {
			font-size: 1.5rem;
		}
	}
}

.section-intro-retailer,
.section-intro-customer {
	
	@include breakpoint($screen-sm) {
		background: linear-gradient($section-bg, darken($section-bg, 2%));
		padding: 4rem 0;
	}

	h3 {
		font-size: 18px;
		margin: 0 0 0.5em;
		font-weight: 600;
		line-height: 20px;
		text-align: center;

		@include breakpoint($screen-lg) {
			text-align: left;
		}
	}

	img {
		width: 90%;
		margin: 0 auto 1.5em;
	}

	p {
		font-weight: 400;
		line-height: 20px;
		font-size: 14px;
		margin: auto auto;
		text-align: center; 

		@include breakpoint($screen-lg) {
			text-align: left;
		}
	}

	.svg-wrapper {
		margin: 0 0 1em;
	}
}

.section-intro-retailer-title {
	background: $zby-blue-dark;
	background: linear-gradient($zby-blue-dark, darken($zby-blue-dark, 4%));
}

.section-intro-customer-title {
	background: $zby-green;
	background: linear-gradient($zby-green, darken($zby-green, 4%));
}

.intro-item {
	margin: 0 0 2em;
	padding: 1.5em;
	background: white;
	border-width: 0;
	border-style: solid;
	border-top-color: #efefef;
	border-bottom-color: #ddd ;
	border-left-color: #eee;
	border-right-color: #eee;
	border-radius: 6px;
	height: 100%;

	.svg-wrapper {
		max-width: 100%;
	}

	@include breakpoint($screen-md) {
		padding: 1.75em;
		margin: 0;
	}
}

.svg-wrapper-no-credit-required {
	padding-bottom: 5px;
}