//  
//  Mixins
//  -----------------------------------------

@import "forms";
@import "alert";

// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

// Transitions - Usage: @include transition(all .2s ease);
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

%gradient-white {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+27,0+100 */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 27%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 27%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 27%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
    /* IE6-9 */
}

%gradient-blue {
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e4f8ff+0,e4f8ff+100&1+38,1+45,0+72 */
background: -moz-radial-gradient(center, ellipse cover,  rgba(228,248,255,1) 0%, rgba(228,248,255,1) 38%, rgba(228,248,255,1) 45%, rgba(228,248,255,0) 72%, rgba(228,248,255,0) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(228,248,255,1) 0%,rgba(228,248,255,1) 38%,rgba(228,248,255,1) 45%,rgba(228,248,255,0) 72%,rgba(228,248,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center,  rgba(228,248,255,1) 0%,rgba(228,248,255,1) 38%,rgba(228,248,255,1) 45%,rgba(228,248,255,0) 72%,rgba(228,248,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4f8ff', endColorstr='#00e4f8ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

%gradient-dusk {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ecfaff+0,fdf5e4+100 */
background: rgb(236,250,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(236,250,255,1) 0%, rgba(253,245,228,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(236,250,255,1) 0%,rgba(253,245,228,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(236,250,255,1) 0%,rgba(253,245,228,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecfaff', endColorstr='#fdf5e4',GradientType=0 ); /* IE6-9 */

}

$debug: false;

$debug-colors: 
mediumorchid,
limegreen,
lightcoral,
violet,
turquoise,
slategray,
tomato,
GOLDENROD,
DARKKHAKI,
ROSYBROWN,
ROYALBLUE,
SANDYBROWN,
TEAL,
MEDIUMORCHID,
MEDIUMPURPLE,
SPRINGGREEN,
ORANGERED,
DARKCYAN,
DARKSLATEBLUE,
DARKSEAGREEN,
PALEVIOLETRED,
crimson;

@mixin debug($attr: null) {
    $length: length($debug-colors);
    $random: random($length);
    $color: nth($debug-colors, $random);
    
    @if $debug == true {
        border: 1px solid $color;

        @if $attr != null {
            position: relative;
            &:after {
                font-family: Menlo, 'Oaks Grotesk', Helvetica Neue, Helvetica, sans-serif;
                font-size: 13px;
                content: attr(#{$attr});
                background: $color;
                color: white;
                text-shadow: 0px 1px 1px rgba(0,0,0,0.8);
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
                padding: 7px;
            }
        }
    }
}

