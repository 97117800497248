//  
//  Tabs
//  -----------------------------------------

ul.tabs {
	display: block;
	padding: 0;
	margin: 0;
	display: flex;
	width: 100%;
}

.tab-item {
	display: block;
	flex: 1 1 100%;
}

.tab-link {

}

.tab-content {

}

.tab-pane {

}