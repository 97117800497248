//  
//  FAQ page
//  -----------------------------------------

.faq-item {
	h3 {
		font-size: 1.2em;
		font-weight: normal;
		display: block;
		margin: 0 0 0.4em;
		color: $zby-blue-dark;
		@include breakpoint($screen-sm) {
			font-size: 1.5em;
		}
		&:hover {
			cursor: pointer;
			color: darken($zby-blue-dark, 8%);
		}
	}
	margin: 0 0 2em;
}

.faq-item-desc {
	display: none;
	visibility: hidden;

	p, ol li, ul li {
		font-size: 16px;
		font-weight: normal;
	}

	ol, ul {
		li {
			line-height: 1.2;
			margin-bottom: 0.4em;
			margin-left: 1em;
		}
	}

	p {
		line-height: 1.4;
	}

	.active + & {
		display: block;
		visibility: visible;
	}
}

.section-faq {
	padding-top: 0;
}