//  
//  Alert
//  -----------------------------------------
$alert-padding: 1rem;
$spacer-y: 0.9rem;
$alert-border-width: 1px;
$alert-border-radius: 2px;
$alert-success-bg: mix($zby-green, white, 25%);
$alert-success-border: $zby-green;
$alert-success-text: mix($zby-green, black, 70%);
$alert-warning-bg: mix($zby-orange, white, 25%);
$alert-warning-border: $zby-orange;
$alert-warning-text: mix($zby-orange, black, 70%);
$alert-info-bg: mix($zby-blue-dark, white, 25%);
$alert-info-border: $zby-blue-dark;
$alert-info-text: mix($zby-blue-dark, black, 70%);
$alert-danger-bg: mix($zby-red, white, 25%);
$alert-danger-border: $zby-red;
$alert-danger-text: mix($zby-red, black, 60%);

.alert {
    padding: $alert-padding;
    margin-bottom: $spacer-y;
    border: $alert-border-width solid transparent;
    border-radius: 3px;
    font-size: 0.9rem;
    font-family: $font-family-ui;
}

.alert-success {
    @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
    @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
    @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
    @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

input,
textarea,
select {
    & + .messages .alert {
        margin-top: 0.5rem;
        transition: 0.3s linear all;
        opacity: 0;

        &.ng-active-add {
            transition: 0.3s linear all;
            opacity: 1;
        }
        &.ng-active {
            opacity: 1;
        }
        &.ng-inactive-add {
            transition: 0.3s linear all;
            opacity: 0;
        }
        &.ng-inactive {
            opacity: 0;
        }
    }
}

.input-wrap .messages {
  position: absolute;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  right: 0;
  font-family: $font-family-ui;
  color: $zby-red;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: $input-padding-y $input-padding-x;
  line-height: $input-line-height * 1.35;
}
