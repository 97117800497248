.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-center {
    text-align: center;
}