//  
//  We help you help them
//  -----------------------------------------
$desaturated: desaturate(#E4F8FF, 80);
$bg-desaturated: linear-gradient($desaturated, darken($desaturated, 3%));
$bg-saturated: linear-gradient(#E4F8FF, darken(#E4F8FF, 4%));
.section-help-you {
    background: $bg-desaturated;
    transition: background 600ms ease;
    padding-bottom: 0;
    &.active {
        background: $bg-saturated;
        transition: background 600ms ease;
    }
    h3 {
        margin: 0 0 1em;
    }
    .floor {
        background: #f9f9f9;
        width: 100%;
        height: 100px;
        position: relative;
        margin-top: -5em;
        &:before {
            display: block;
            content: " ";
            position: absolute;
            background: white;
            top: 0;
            left: 0;
            right: 0;
            height: 20px;
            border-bottom: 1px solid darken(#fff, 5%);
        }
    }
}

.col-help-you {
    text-align: center;
    @include column(12, 12, 12, 12);
    .btn {
        margin: 0 0 2em;
    }
}

.svg-wrapper-couch {
    position: relative;
    width: 100%;
    z-index: 100;
    height: 0;
    padding: 0 0 percentage(528/1233);
    .svg-couch {
        position: absolute;
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

#couch {
    &-chair1 {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        .active & {
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 150ms;
        }
    }
    &-painting-right {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        display: none;
        .active & {
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 300ms;
        }
    }
    &-painting-left {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        display: none;
        .active & {
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 450ms;
        }
    }
    &-shelf1 {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        .active & {
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 600ms;
        }
    }
    &-sidetable1 {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        .active & {
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 750ms;
        }
    }
    &-flowers1 {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        .active & {
            margin-top: 0;
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 900ms;
        }
    }
    &-lamp1 {
        opacity: 0;
        transition: all 600ms $easeOutExpo;
        .active & {
            opacity: 1;
            transition: all 600ms $easeOutExpo;
            transition-delay: 1050ms;
        }
    }
}

.img-painting-left,
.img-painting-right {
    position: absolute;
    width: 19.733751%;
}

.img-painting-left {
    left: 15.6617071%;
    top: 8.2164782%;
}

.img-painting-right {
    left: 62.2012529%;
    top: 8.2164782%;
}

.img-painting-right {
    opacity: 0;
    transition: all 600ms $easeOutExpo;
    .active & {
        opacity: 1;
        transition: all 600ms $easeOutExpo;
        transition-delay: 300ms;
    }
}

.img-painting-left {
    opacity: 0;
    transition: all 600ms $easeOutExpo;
    .active & {
        opacity: 1;
        transition: all 600ms $easeOutExpo;
        transition-delay: 450ms;
    }
}
