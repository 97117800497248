//  
//  Section Customer 2
//  -----------------------------------------

.col-customer-copy {
	@include column(6, 6, 12, 12);
}

.col-customer-heads {
	@include column(6, 6, 12, 12);
}

.copy-grow-your-customer-base {
	text-align: center;

    @include breakpoint($screen-md) {
        text-align: left;

         p {
         	// max-width: 23em;
         }
    }
}

.heads-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 0;
    

    @include breakpoint($screen-sm) {
    	padding: 2em 0;
    	margin-left: 20px;
    }

    @include breakpoint($screen-lg) {
    	margin-left: 34px;
    }
}

.head {
	float: left;
	flex: 1 0 50%;
	width: 50%;
	padding: 1em 0.5em;
	animation: 150ms fadeInDown ease-in-out;
	opacity: 0;
	animation-fill-mode: forwards;
	margin: 0 0 2em;

	@include breakpoint($screen-sm) {
		padding: 0;
	}

	@for $i from 1 through 4 {
		&:nth-child(#{$i}) {
			animation-delay: 1s + ($i * 150ms);
		}
	}

	#blue-Background,
	#green-Background,
	#purple-Background,
	#pink-Background {
		animation: 150ms bounceIn ease-in-out;
		opacity: 0;
		animation-fill-mode: forwards;
	}

	#blue-eyes-Background {
		animation-delay: 1.5s + (1 * 150ms);
	}

	#green-Background {
		animation-delay: 1.5s + (2 * 150ms);
	}

	#purple-Background {
		animation-delay: 1.5s + (3 * 150ms);
	}

	#pink-Background {
		animation-delay: 1.5s + (4 * 150ms);
	}
}

#blue-eyes-Eyes {
	opacity: 0;
	animation: blink 5s infinite;
}

#blue-eyes-OpenEyes {
	opacity: 1;
	animation: open 5s infinite;
}

.head-wrapper {
	width: 100%;
	height: 0;
	padding-bottom: percentage(128 / 155);
	position: relative;

	svg {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}