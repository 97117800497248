//  
//  Scroll Scene
//  -----------------------------------------

$debug: true;

%transition {
	transition: all 200ms ease-in-out;
}

.section-customer-3 {
	// min-height: 200vh;
}

.scroll-scene-wrapper {
	// display: flex;
}

.scroll-nav-wrapper {
	width: 100%;
	@include debug;
}

ul.scroll-nav {
	padding: 0;
	margin: 0;
	padding: 0;
	overflow: visible;
	border: 1px solid #E4F8FF;
	float: left;
	
	li {
		padding: 25px;
		margin: 0;
		width: 33.333%;
		float: left;
		list-style: none;
		cursor: pointer;
		position: relative;
		overflow: visible;
		text-align: center;

		@include debug('class');
	}
}

	.item-title, 
	.item-subtitle {
		display: block;
	}

	.item-title {
		font-size: 26px;
		letter-spacing: 0.2px;
		line-height: 30px;
		color: #9B9B9B;
		@extend %transition;

		.active & {
			color: $zby-blue-dark;
			@extend %transition;
		}
	}

	.item-subtitle {
		color: transparent;
		font-size: 14px;
		letter-spacing: 0.4px;
		line-height: 16px;
		font-weight: 400;
		@extend %transition;
		transform: translate(0, 20px);

		.active & {
			color: $zby-blue-dark;
			transform: translate(0, 0);
			@extend %transition;
		}
	}

.scene-single {
	position: absolute;
	width: 100%;
	display: block;
}

$base-height: 800px;

@for $i from 1 through 3 {
	#scene-#{$i} {
		top: ($i * $base-height) - $base-height;
	}
}

.scroll-content {
	position: relative;
	width: 100%;
	padding: 0 15px;
	@include debug;

	img {
		width: 100%;
	}
}

.pinned-content {
	@include debug('class');
}

@keyframes fadeIn {
    from { 
    	opacity: 0; 
    	transform: translate(0, 20px);
    }
    to { 
    	opacity: 1; 
    	transform: translate(0, 0);
    }
}

@keyframes fadeOut {
    from { 
    	opacity: 1; 
    	transform: translate(0, 0);
    }
    to { 
    	opacity: 0; 
    	transform: translate(0, 100px);
    }
}

.item-inner {
	width: 100%;
	top: 0;
	opacity: 0;
	display: none;
	animation: fadeOut 300ms ease-in-out;

	img {
		width: 100%;
		margin-top: -40px;

	}

	&.active {
		display: block;
	    animation: fadeIn 300ms ease-in-out;
		opacity: 1;
	}
}

#main-scene {
	padding: 4em 0;
}

$base-line: 71px;
$zby-gradient: linear-gradient(-180deg, #7ED321 0%, #2FC3AC 66%, #00B9FF 100%);

#line {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	background: $zby-gradient;
	width: 2px;
	max-height: 0;
	height: 800px;

	&.fish-one {
		max-height: ($base-line) + 25px;
	}

	&.fish-two {
		max-height:( $base-line * 2) + 25px;
	}

	&.fish-three {
		max-height: ($base-line * 3) + 25px;
	}
}



















