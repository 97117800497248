//  
//  Video
//  -----------------------------------------

.easyhtml5video {
    position:absolute;
    width: 100%;
    height: 100%;

    &.ng-animate {
        transform: translateZ(0);
    }

    video {
        position: absolute;
    }
}

.video-container {
    width: 100%;
    height: 100vh;
    max-height: 600px;
    background-color: $zby-gray-darker;
    background: linear-gradient($zby-gray-darker, darken($zby-gray-darker, 5%));

    @include breakpoint($screen-md) {
    	height: 100%;
    }

    video {
    	object-fit: cover;
        width: 100%;
        height: 100%;
        
        @include breakpoint($screen-md) {
	    	height: 100%;
	    }
    }
}