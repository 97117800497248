//  
//  Compare Tool
//  -----------------------------------------

#container1 {
	min-height: 400px !important;
	width: 100%;

	img {
		width: 100%;
	}
}