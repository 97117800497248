//  
//  Carousel
//  -----------------------------------------

%gradient-white-fade-left {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+9,0+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 9%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 9%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 9%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
}

%gradient-white-fade-right {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+91 */
background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 91%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 91%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 91%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}

.carousel {
	@include debug;
	overflow: hidden;
	width: 100%;
	position: relative;
	margin: 0 0 4em;

	&:before,
	&:after {
		@include debug;
		position: absolute;
		display: block;
		content: " ";
		top: 0;
		bottom: 0;
		width: 75px;
	}

	&:before {
		right: 0;
		@extend %gradient-white-fade-right;
	}

	&:after {
		left: 0;
		@extend %gradient-white-fade-left;
	}
}

.carousel-nav {
	margin: 0;
	@include debug;
	max-width: 90%;
	margin: 0 75px;

	li {
		@include debug;
		list-style: none;
		display: inline-block;
		padding: 0;
		cursor: pointer;
		max-width: 19%;
		width: 100%;
		padding: 0 15px;
		vertical-align: top;
	}

	img {
		width: 100%;
		max-width: 100%;
	}
}

.retailer {
	width: 100%;
	padding: 0 0 50%;
	overflow: hidden;
	background-image: url('http://placehold.it/300x300');
	height: 0;
	position: relative;
	margin-top: 8px;
	transition: margin 400ms ease;

	.active & {
		transition: margin 400ms ease;
		margin-top: 0 !important;
	}
}

$images: "ashley", "mba", "sleepys", "sears";

@each $image in $images {
	.retailer-#{$image} {
		background-size: 200%;
		background-position: center right;
		background-repeat: no-repeat;
		background-image: url('../images/logos/#{$image}.png');

		.active & {
			margin-top: -20px;
			background-position: center left;
		}
	}
}

.carousel-inner {
	@include debug;
	width: 500%;
	text-align: left;
	transition: all .8s ease-in-out;
	position: relative;

	.item {
		@include debug;
		text-lign: center;
		width: 20%;
		display: block;
		float: left;
		margin: 0;
		padding: 2em 100px;
		transition: all .8s ease-in-out;

		p {
			text-align: center;
		}
	}
}


