//  
//  Icons
//  -----------------------------------------

$icons: (
	kitchen: $zby-purple,
	tree: $zby-orange,
	house: $zby-blue-dark
);

.svg-icon-wrapper {
	width: 100%;
	max-width: 52px;
	position: relative;
	margin: auto auto;

	> [class^="svg-icon"], {
		position: relative;
		height: 52px;
		width: 52px;
		padding: 0 0 100%;

		svg {	
			transition: all 200ms ease-in-out;
			position: absolute;
			width: 100%;
			height: 100%;

			ellipse {
				fill: #E0E2E3; 
				transition: all 200ms ease-in-out;
			}
		}

		&:hover {
			cursor: pointer;

			svg {
				transition: all 200ms ease-in-out;
				transform: scale(1.2, 1.2);
			}

			svg ellipse {
				// fill: red;
				transition: all 200ms ease-in-out;
			}
		}
	}
}

// Active icons
@each $name, $color in $icons {
	.active .svg-icon-#{$name} svg ellipse{
		fill: $color;

		&:hover {
			fill: darken($color, 10%);
		}
	}
}

// Hover icons
@each $name, $color in $icons {
	.svg-icon-#{$name}:hover svg ellipse{
		fill: $color;
	}
	
}