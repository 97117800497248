//  
//  FAQ page
//  -----------------------------------------

.content-404 {
	min-height: 80vh;

	@include breakpoint($screen-sm) {
		min-height: 800px;
	}
}