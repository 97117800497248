@font-face {
    font-family: 'Oaks Grotesk';
    src: url('[base]/assets/fonts/Oakes-Grotesk-Light.woff2') format('woff2'),
    url('[base]/assets/fonts/Oakes-Grotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oaks Grotesk';
    src: url('[base]/assets/fonts/Oakes-Grotesk-Regular.woff2') format('woff2'),
    url('[base]/assets/fonts/Oakes-Grotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oaks Grotesk';
    src: url('[base]/assets/fonts/Oakes-Grotesk-Medium.woff2') format('woff2'),
    url('[base]/assets/fonts/Oakes-Grotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oaks Grotesk';
    src: url('[base]/assets/fonts/Oakes-Grotesk-Bold.woff2') format('woff2'),
    url('[base]/assets/fonts/Oakes-Grotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
