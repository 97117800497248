//
//  Logo
//  -----------------------------------------

.logo {
    position: relative;
    font-size: 0px;

    a {
        background-image: url('[base]/assets/images/logo-white-desaturated.png');
        background-position: center center;
        background-repeat: no-repeat;
        display: block;
        background-size: 75px 33px;
        position: relative;
        width: 90px;
        height: 45px;
        padding: 0;
        margin: 2px 0 0;
    }
}

.sticky .logo a {
    background-image: url('[base]/assets/images/logo-black.png');
}

.logo-nav {
    float: left;
    width: 110px;
    transform: scale(1.6);
    transform-origin: 30% 50%;
    height: 42px;

    a {
        background-image: url('[base]/assets/images/logo-white.png');
        background-size: 75px 33px;
        position: relative;
        display: block;
        width: 90px;
        height: 45px;
        padding: 0;
        margin: 2px 0 0;
    }

    @include breakpoint($screen-sm) {
        width: 120px;

        a {
            background-image: url('[base]/assets/images/logo-white.png');
        }
    }
}

.logo-footer {
    width: 100%;
    max-width: 120px;
    margin: 0 auto 1em;
    display: block;

    a {
        background-image: url('[base]/assets/images/logo-black.png');
    }
}

.retailer-logo {
    display: block;
    width: 100%;
    padding: 0 0 percentage(168/196);
    background: pink;
    height: 0;
    background-position: 0 top;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.6;
    cursor: default;

    &:hover {
        opacity: 1;
        cursor: default;
    }

}

.logo-ghostbed {
    background-image: url('[base]/assets/images/logos/ghostbed.jpg');
}

.logo-sears {
    background-image: url('[base]/assets/images/logos/sears.jpg');
}

.logo-ashley {
    background-image: url('[base]/assets/images/logos/ashley.jpg');
}

.logo-bedmart {
    background-image: url('[base]/assets/images/logos/bedmart.jpg');
}

.logo-lacks {
    background-image: url('[base]/assets/images/logos/lacks.jpg');
}

.logo-lenovo {
    background-image: url('[base]/assets/images/logos/lenovo.jpg');
}
