//  
//  Buttons
//  -----------------------------------------

@function get-button-text-color($bgcolor) {
    @if (lightness($bgcolor) > 70) {
        @return $zby-gray-darker;
    } @else {
        @return white;
    }
}

@keyframes fadeInButtonText {
    0% {
        color: rgba($zby-green, 0);
        transform: scale(1.3, 1.3);
    }
    100% {
        color: rgba($zby-green, 1);
        transform: scale(1, 1);
    }
}

@keyframes fadeInButtonTextError {
    0% {
        color: rgba($zby-red, 0);
        transform: scale(1.3, 1.3);
    }
    100% {
        color: rgba($zby-red, 1);
        transform: scale(1, 1);
    }
}

@mixin create-button($name, $bgcolor) {
    .btn-#{$name} {
        transition: background 200ms ease-in-out;
        background: rgba($bgcolor, 1);
        color: get-button-text-color($bgcolor);

        @if (lightness($bgcolor) > 70) {
            border: 1px solid darken($bgcolor, 20%);
        }

        &:hover {
            background: rgba(darken($bgcolor, 5%), 1);
            color: get-button-text-color($bgcolor);
        }

        &:focus {
            background: darken($bgcolor, 5%);
        }

        &.disabled {
            &:hover {
                background: $bgcolor;
            }
        }

        &.complete {
            transition: background 200ms ease-in-out;
            background: rgba($bgcolor, 0);
            color: rgba(white, 0);
            animation: fadeInButtonText 200ms ease-in-out 250ms forwards;

            &:hover {
                background: rgba($bgcolor, 0);
                color: darken($zby-green, 10%);
            }

            &.error {
                background: rgba($bgcolor, 0);
                transition: background 200ms ease-in-out;
                color: rgba(darken($zby-red, 10%), 0);
                animation: fadeInButtonTextError 200ms ease-in-out 250ms forwards;
            }
        }
    }
}

.border {
    border: 10px solid border;
}

$buttons: (
        primary: $zby-blue-dark,
        secondary: white,
        success: $zby-green,
        info: $zby-blue-dark,
        warning: $zby-orange,
        danger: $zby-red,
);

.btn {
    display: inline-block;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    border: 1px solid transparent;
    padding: 0.55rem 1.3rem;
    font-size: 1rem;
    font-family: $font-family-ui;
    border-radius: 30px;

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

.btn-lg {
    padding: 0.8rem 1.5rem;
}

@each $button, $bgcolor in $buttons {
    @include create-button($button, $bgcolor);
}

.btn-link {
    background: transparent;

    &:hover {
        text-decoration: underline;
    }
}

.btn-ghost {
    background: transparent;
    border-width: 1px;
    border-style: solid;

    @each $button, $bgcolor in $buttons {
        &.btn-#{$button} {
            border-color: $bgcolor;
            color: white;
            // transition: all 200ms ease;

            &:hover {
                background: $bgcolor;
                border-color: $bgcolor;
                // transition: all 0 ease;
                color: white;
            }

            &.active {
                background: $bgcolor;

                &:hover {
                    background: darken($bgcolor, 2%);
                }
            }
        }
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-giant {
    width: 100%;
    padding: 3rem 0;
    font-size: 23px;

    border-radius: 0;
}

@keyframes loadering {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes starter {
    0% {
        -webkit-transform: translate(0, 40px);
        transform: translate(0, 40px);
        border-left-color: rgba($zby-blue-dark, 0.2);
    }
    99% {
        border-left-color: rgba($zby-blue-dark, 0.2);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        border-left-color: $zby-blue-dark;
    }
}

.loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 3px solid rgba(white, 0.2);
    border-right: 3px solid rgba(white, 0.2);
    border-bottom: 3px solid rgba(white, 0.2);
    border-left: 3px solid rgba(white, 0.6);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    animation: loadering 800ms infinite linear, starter 250ms $easeOutQuad;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.btn.loading {
    position: relative;
    color: transparent;
    overflow: hidden;
}

.btn.disabled {
    opacity: 0.8;
    cursor: default;
}

.btn-primary {
    background: $zby-purple;
    color: white;
    &:hover, &:focus {
        background: $zby-blue;
    }
}
