//  
//  Copy
//  -----------------------------------------

$debug: true;

.copy {
	font-size: 14px;
	text-align: center;
	margin: 0 0 2em;

	@include breakpoint($screen-sm) {
		text-align: left;
		font-size: 14px;
	}

	h3 {
		font-size: 1.6em;
		margin-bottom: 0.5em;
		margin-top: 0;
		font-weight: 600;
	}

	p {
		font-size: 1em;
		line-height: 1.5;
		letter-spacing: 0.18px;
		font-weight: 400;
	}
}

.copy-white {
	text-align: center;

	h2, h3 {
		font-style: normal;
	}
}

.copy-section {
	align-items: center;
}