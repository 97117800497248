//
//  Anytime anywhere
//  -----------------------------------------

.section-anytime-anywhere {
    position: relative;
    padding: 0;
}

.triggers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .trigger {
        flex: 1 1 33%;
    }
}

.col-iphone-copy {
    @include column(6,6, 12, 12);
    @include order(2,2,1,1);
}

.col-iphone-image {
    @include column(6, 6, 12, 8);
    @include columns-before(0, 0, 0, 2);
    @include order(1,1,2,2);
}

.svg-wrapper-iphone {
    width: 100%;
    max-width: 300px;
}

.svg-iphone {
    position: relative;
    width: 100%;
    padding: 0 0 percentage(724/355);

    svg {
        position: absolute;
        top:0;
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .screen {
        position: absolute;
        top: 0;
        z-index: 1;
        left: 6.5%;
        right: 6%;
        top: 11.5%;
        bottom: 12%;
        overflow-x: hidden;
        border: 1px solid #eee;
    }

    .screen-carousel {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
    }

    .slide {
        position: absolute;
        left: 0;
        right: 0;
        top: 42px;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: all 250ms ease-in-out;
        transform: translate(0, 14px);
        background-color: white;

        &.active {
            opacity: 1;
            transition: all 250ms ease-in-out;
            transform: translate(0, 0);
        }
    }

    .slide-1 {

    }

    .slide-2 {
        // background-image: url(http://lorempixel.com/300/700/sports/2);
    }

    .slide-3 {
        // background-image: url(http://lorempixel.com/300/700/sports/3);
    }
}

.app-nav {
    background: $zby-blue-dark;
    padding: 12px 10px;
    text-align: center;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    color: white;
    font-size: 18px;
    line-height: 1;
    margin-left: -10px;
    margin-right: -10px;
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    font-size: 14px;

    @include breakpoint($screen-sm) {
        font-size: 18px;
    }

    &:after {
        display: block;
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        background: darken($zby-blue-dark, 5%);
        bottom: 0;
        height: 2px;
    }
}

.svg-wrapper-cart {
    width: 17px;
    position: absolute;
    top: 8px;
    right: 29px;

    @include breakpoint($screen-sm) {
        right: 32px;
        top: 6px;
        width: 24px;
    }

    .svg-cart {
        position: relative;
        width: 100%;
        padding: 0 0 percentage(30/26);

        svg {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}

.app-content {
    width: 100%;
    padding: 0 10px;
    float: left;
}

.app-button-wrapper {
    padding: 0 5px;
}

@keyframes fadeOutButton {
    0% {
        opacity: 1;
    }

    20% {
        transform: scale(1.1, 1.1);
    }

    45% {
        transform: scale(1.1, 1.1);
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

$bgcolor1: rgb(254,254,254);
    $bgcolor2: rgb(241,241,241);

@keyframes changeButton {
    0% {
        background-image: linear-gradient(rgb(254,254,254), rgb(241,241,241));
        box-shadow: 0 3px 3px rgba(182, 182, 182, 0.35);
    }
    100% {
        background-image: linear-gradient(darken($bgcolor1, 5%), darken($bgcolor2, 5%));
        box-shadow: inset 0px 4px 8px rgba(0,0,0,0.2), 0 0px 2px rgba(182, 182, 182, 0.35);
    }
}

.app-button {
    display: block;
    width: 100%;
    position: relative;
    padding: 8px 0 8px 10px;
    background: white;
    border: 1px solid rgb(208,208,208);
    border-radius: 5px;
    background-image: linear-gradient(rgb(254,254,254), rgb(241,241,241));
    box-shadow: 0 3px 3px rgba(182, 182, 182, 0.35);
    margin: 10px 0 15px;
    transition: all 200ms ease;
    transition-delay: 500ms;

    @include breakpoint($screen-sm) {
        padding: 10px 0 10px 13px;
        border-radius: 10px;
        margin: 20px 0;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: " ";
        position: absolute;
        right: -100px;
        top: 0;
        bottom: -100px;
        margin: auto auto;
        width: 30px;
        height: 30px;
        background: $zby-orange;
        border-radius: 50%;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
        border: 5px solid white;
        transition: all 500ms ease;
    }

    &.active {
        animation: changeButton 500ms ease 800ms forwards;

        &:after {
            right: 7%;
            top: 0;
            bottom: 0;
            transition: all 500ms ease;
            animation: fadeOutButton 400ms ease-out 700ms forwards;
        }
    }
}

.app-text {
    display: block;
    text-align: left;
    font-size: 12px;
    line-height: 1.3;

    @include breakpoint($screen-sm) {
        font-size: 16.5px;
        line-height: 1.2;
    }

    &.app-text-get {
        color: $zby-blue-dark;
    }

    &.app-text-with {
        font-weight: 500;
    }
}

.app-button-logo {
    float: left;
    width: 18%;
    position: relative;
    margin: 0 26px 0 0;

    &:after {
        content: " ";
        width: 1px;
        background: rgb(208,208,208);
        position: absolute;
        top: -10px;
        bottom: -10px;
        right: -10px;
    }

    @include breakpoint($screen-sm) {
        &:after {
            top: -10px;
            bottom: -10px;
            right: -16px;
        }
    }
}

.app-grid {
    width: 100%;
    position: relative;
    float: left;

    .app-grid-item {
        float: left;
        width: 50%;
        padding: 0 5px 10px 5px;

        img {
            border-radius: 3px;
            margin: 0 0 7px;
            border: 1px solid $zby-gray-light;
        }

        .item-desc {
            width: 100%;
            height: 7px;
            background: #eee;
            margin-bottom: 14px;

            &:after {
                width: 75%;
                content: " ";
                position: relative;
                background: #eee;
                height: 7px;
                display: block;
                left: 0;
                top: 14px;
            }
        }
    }
}

.app-form-wrapper {
    width: 100%;
    padding: 0 5px;

    .app-form-intro {
        margin: 12% 0 4%;
        text-align: center;

        img {
            max-width: 60%;
            margin: 0 auto;
        }

        span {
            display: block;
            margin: 10px 0;
            font-size: 14px;
            color: #444;
        }
    }

    .app-form {
        button {
            width: 100%;

            position: relative;

            @include breakpoint($screen-sm) {
                margin-top: 10px;
            }

            &.active {
                background: darken($zby-blue-dark, 5%);
                top: 2px;
                box-shadow: inset 0px 2px 3px rgba(0,0,0,0.15);
            }
        }
    }

    .app-form-item {
            width: 100%;
            border: 1px solid rgb(214, 214,214);
            padding: 8px 10px;
            border-radius: 3px;
            margin: 0 0 10px;

            @include breakpoint($screen-sm) {
               padding: 13px 16px;
            }

            p {
                padding: 0;
                margin: 0;
                font-size: 13px;
                color: rgba($zby-gray-darker, 0);
                transition: all 250ms ease;
                transform: translate(-10px, 0);

                @include breakpoint($screen-sm) {
                    font-size: 15px;
                }
            }

            &.active {
                p {
                    color: rgba($zby-gray-darker, 1);
                    transition: all 250ms ease;
                    transform: translate(0, 0);
                }
            }

        }

        .app-form-label {
            display: none;
            font-size: 0.7em;
            margin: 0 0 0.4em;
            line-height: 1;

            @include breakpoint($screen-sm) {
                display: block;
            }
        }
}

@keyframes fadeInUpCheck {
    0% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.checkmark {
    opacity: 0;
    position: relative;

    .active .app-content & {
        animation: fadeInUpCheck 400ms ease-out 200ms forwards;
    }
}

.svg-wrapper-check {
    width: 65%;
    margin: 10% auto;

    @include breakpoint($screen-sm) {
        width: 80%;
        margin: 20% auto 10%;
    }

    .svg {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 percentage(82.13/106.68);

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}

.app-message {

    .amount {
        position: relative;
        width: 100%;
        display: block;
        text-align: center;
        font-weight: 400;
        color: #0899D2;
        font-size: 23px;
        margin: 0 0 10px;

        @include breakpoint($screen-md) {
            font-size: 27px;
        }
    }

    .message {
        position: relative;
        width: 80%;
        margin: 0 auto;
        display: block;
        text-align: center;
        font-size: 14px;
        line-height: 1.3;

        @include breakpoint($screen-md) {
            width: 100%;
            font-size: 16px;
        }
    }

    .btn {
        margin-top: 30px;
        width: 100%;
        font-size: 14px;
        padding: 8px 10px;

        &:hover {
            cursor: default;
            background: $zby-blue-dark;
        }

        @include breakpoint($screen-sm) {
            padding: 10px 16px;
            font-size: 16px;
        }
    }
}

.copy-anytime-anywhere {
    text-align: center;

    @include breakpoint($screen-md) {
        text-align: left;
        // margin-left: 3.5em;

         p {
            // max-width: 21em;
         }
    }
}

.iphone-slide {
    display: none;

    &.active {
        display: block;
    }
}

@keyframes upAndDown {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 10px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.chevron {
    background: url('[base]/assets/images/chevron.svg');
    width: 100%;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    margin: auto;
    background-position: center center;
    animation: upAndDown 2s ease-in-out infinite;
    opacity: 0.5;
    z-index: 10;
    transition: all 400ms ease;
    transform: scale(1, 1);
    opacity: 0;

    &.chevron-first,
    &.chevron-second {
        transition: all 400ms ease;
        transform: scale(0.8, 0.8);
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    .link-1 {
        display: block;
    }

    .link-2 {
        display: none;
    }

    &.chevron-second {
        .link-1 {
            display: none;
        }
        .link-2 {
            display: block;
        }
    }
}
