//  
//  Typography
//  
//  Inspired by https://github.com/AdamMarsden/simple-typography
//  -----------------------------------------

*, html, body {
    font-size: $base-font-size;
    font-family: $font-family-sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

// Headers
h1, h2, h3, h4, h5, h6 {
    margin: $header-margin * 1.5 0 $header-margin 0;
    color: $header-color;
    line-height: $header-line-height;
}

h1 {
    font-size: $header-size;
}

h2 {
    font-size: $header-size / 1.3;
    font-weight: 600;
}

h3 {
    font-size: $header-size / 1.5;
}

h4 {
    font-size: $header-size / 1.7;
}

h5 {
    font-size: $header-size / 1.8;
}

h6 {
    font-size: $header-size / 2;
}



// Paragraphs
p {
    font-size: $paragraph-size;
    line-height: $paragraph-line-height;
    color: $paragraph-color;
    margin: 0 0 $paragraph-margin;

    &:last-of-type {
        margin-bottom: 0;
    }
}



// Links
a {
    color: $zby-purple;
    text-decoration: none;

    &:hover {
        color: darken($zby-purple, 10%);
    }
}


// Lists
ul, ol {
    margin: 0 0 $paragraph-margin 20px;

    li {
        margin-top: 10px;
        color: $paragraph-color;
        line-height: $paragraph-line-height;
        font-size: $paragraph-size;
    }

    ul, ol {
        margin-top: 0;
    }
}

ul {

    li {
        list-style: disc;
    }

    ul {

        li {
            list-style: circle;
        }
    }
}

ol {

    li {
        list-style: decimal;
    }
}

ol[type="a"] {
    li {
        list-style: lower-latin;
    }
}

// hr
hr {
    height: 1px;
    margin: $hr-margin 0;
    border: none;
    background-color: $border-color;
}


// Blockquote
blockquote {
    margin: $blockquote-margin 0;
    font-size: $blockquote-size;
    line-height: $blockquote-line-height;
    color: $blockquote-color;
    text-align: center;
}


// Tables
table {
    width: 100%;
    margin: $blockquote-margin 0;
    border-collapse: collapse;
}

table, td, th {
    border: 1px solid $border-color;
    text-align: left;
}

th {
    font-weight: $font-weight-bold;
}

th, td {
    padding: 15px;
}


// Figure
figure {
    margin: $blockquote-margin 0;

    img {
        margin: 0;
    }
}

figcaption {
    margin-top: 10px;
    color: lighten($paragraph-color, 10%);
    font-size: $paragraph-margin * 0.8;
}


// Code
pre {
    display: block;
    margin: 0 0 40px 0;
    padding: 20px;
    background-color: lighten($border-color, 12%);
    border: 1px solid $border-color;
    overflow: auto;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    code {
        position: static;
        padding: 0;
        border: none;
        line-height: 1.5em;
    }
}

code {
    position: relative;
    top: -0.2em;
    padding: 3px;
    font-family: $font-family-monospace;
    font-size: $paragraph-size / 1.4;
    color: darken($border-color, 40%);
    line-height: 1em;
    pointer-events: none;
    border: 1px solid $border-color;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}



// Misc Styles
em, i, .italic {
    font-style: italic;
}

strong, b, .bold {
    font-weight: $font-weight-bold;
}

img {
    display: block;
    max-width: 100%;
}