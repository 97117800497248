//  
// 	Logos
//  -----------------------------------------

.section-logos {
	background: $zby-blue-dark;
	background: linear-gradient($zby-blue-dark, darken($zby-blue-dark, 3%));

	@include breakpoint($screen-sm) {
		padding: 5em 0;
	}

	h2 {
		color: white;
		margin: 0 0 1.3em;
		text-align: center;
	}
}

.logos-list {
	margin-bottom: -30px;

	.logo-list-item {
		border-radius: 6px;
		overflow: hidden;
		background: white;
		padding: 0.6em;
		margin-bottom: 30px;

		&:hover {
			& .retailer-logo {
				background-position: 0 bottom;
			}
		}

		@include breakpoint($screen-md) {
			padding: 0.6em;
		}

		img {
			width: 100%;
		}
	}
}