//  
//  Variables
//  -----------------------------------------

//
//	Media Sizes
//
$screen-sm: 544px;
$screen-md: 720px;
$screen-lg: 960px;
$screen-xl: 1200px;
$grid-width: $screen-lg;

//
//	Colors
//
//$zby-blue-dark: #035B7E;
//$zby-blue: #00B9FF;
//$zby-gray-light: #A8ACAF;
//$zby-gray: #2D3037;
//$zby-orange: rgb(237, 184, 52);
//$zby-purple: #5AC5CF;
//
//$zby-button-blue: #4A90E2;
//$zby-button-orange: #FF9C37;
//$zby-button-teal: #4BD1B3;


$zby-background: #f5f7fa;

$zby-white: #ffffff;
$zby-gray-light: #fafafa;
$zby-gray-medium: #CBCACA;
$zby-gray-dark: #979797;
$zby-gray-darker: #2d2d2d;
$zby-black: #000000;

$zby-blue-lightest: #dce8f1;
$zby-blue-lighter: #c6d7e6;
$zby-blue-light: #808080;
$zby-blue-dark: #131340;
$zby-blue-darker: #283349;
$zby-purple-light: #f9b2dc;
$zby-purple: #ED5370;
$zby-blue: #364488;
$zby-green: #7ED321;
$zby-red: #D0021B;
$zby-orange: #ea7822;
$zby-violet: #772b84;

//
//	Z-index
//
$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-full-video:        1020 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;

//
//	Typography
//
$font-family-sans-serif: 'Oaks Grotesk', 'Helvetica Neue', Helvetica, sans-serif;
$font-family-monospace: Menlo, Courier New, Courier, monospace;
$font-family-ui: 'Oaks Grotesk', Helvetica Neue, Helvetica, sans-serif;
$font-family-apple: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Base Font for HTM
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color      : #505050;
$paragraph-size       : rem(19px);
$paragraph-line-height: 1.5em;
$paragraph-margin     : 20px;

// Header Styles
$header-color      : $zby-blue-dark;
$header-size       : rem(46px);
$header-line-height: 1.25em;
$header-margin     : $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color      : #505050;
$blockquote-size       : rem(23px);
$blockquote-line-height: 1.5em;
$blockquote-margin     : $paragraph-margin * 1.5;

// Links
$link-color: $zby-blue-dark;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 700;
$border-color: #DADADA;

