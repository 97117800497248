//  
//  Get started
//  -----------------------------------------
@keyframes fadeInCheck {
    0% {
        opacity: 0;
        transform: scale(0.8, 0.8);
    }
    50% {
        opacity: 1;
    }
    70% {
        transform: scale(1.1, 1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.section-get-started {
    padding-top: 0;
}

.page-get-started {
    background: #F4F4F4;
    background: linear-gradient(#F4F4F4, darken(#F4F4F4, 3%));
    position: relative;

    .site-footer {
        background: white;
    }
}

.get-started-container {
    border-radius: 5px;
    background: white;
    margin: 0 -15px 0;
    border: none;
    position: relative;
    max-width: 500px;
    padding: 15px;
    box-shadow: 0 1px 5px transparentize(black, .9);

    @include breakpoint($screen-sm) {
        margin: 2em auto;
        padding: 2rem;
    }

    .ng-animate {
        // transition: all 20s ease;
    }
}

.group-image {
    max-width: 200px;
    margin: 0 auto 20px;
}

.col-get-started-form {
    @include column(12, 12, 12, 12);
    align-items: center;
    transition: width 250ms ease-in-out;

    .get-started-header {
        display: block;
        margin: 0.4em 0 0.7em;
        @include breakpoint($screen-lg) {
            display: none;
        }
    }

    .form-check {
        margin: 0;
        display: flex;
        justify-content: center;
    }

    form {
        &.ng-hide-add {
            transition: all 0s linear !important;
        }

        &.ng-hide-remove {
            transition: all 0s linear !important;
        }

        &.ng-hide-add {
            // display: none !important;
        }

        &.ng-hide-add.ng-hide-add-active {
            // display: none !important;
        }

        &.ng-hide-remove {
            display: none !important;
        }

        &.ng-hide-remove.ng-hide-remove-active {
            display: none !important;
        }

        .alert {
            margin: 15px 0 0;
        }
    }
}

h1.get-started-mobile-header {
    display: block;
    text-align: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

p.get-started-copy {
    font-size: 0.9em;
    line-height: 1.35;
    margin: 0 0 2em;
}

.form-group-radio {
    padding: 1em;
    border-radius: 4px;
    font-weight: 500;
}

.get-started-svg-wrapper {
    div {
        width: 100%;
    }
}

.get-started-form-success {
    display: flex;
    flex: 0 0 auto;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        flex: 0 0 auto;
        text-align: center;

        h1,
        p {
            display: block;
            max-width: 400px;
            text-align: center;
            margin: 0 0 15px;
        }
    }

    &.ng-hide-add {
        transition: all 0s linear !important;
    }

    &.ng-hide-remove {
        transition: all 0s linear !important;
    }
}

.check-wrapper {
    width: 100%;
    max-width: 160px;
    margin: 0 0 20px;

    .green-check {
        position: relative;
        width: 100%;
        padding-bottom: percentage(61/79);
        opacity: 0;
        animation: fadeInCheck 600ms $easeInOutCubic 400ms forwards;

        div {
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: url('[base]/assets/images/check.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}
