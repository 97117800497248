//  
//  Angular
//  -----------------------------------------

// ui-view

[ui-view].ng-enter, 
[ui-view].ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  transition:all .25s ease-in-out;
}

[ui-view].ng-enter {
  opacity: 0;
  transform:translate3d(0, 0, 0) translateZ(0);
  // transform:scale3d(0.5, 0.5, 0.5);
}

[ui-view].ng-enter-active {
  opacity: 1;
  transform:translate3d(0, 0, 0) translateZ(0);
  // transform:scale3d(1, 1, 1);
}

[ui-view].ng-leave {
  opacity: 1; 
  transform:translate3d(0, 0, 0) translateZ(0);
}

[ui-view].ng-leave-active {
  opacity: 0;
  transform:translate3d(0, 0, 0) translateZ(0);
}

// more show
.ng-hide-add, 
.ng-hide-remove {
  transition: 0s linear all;
}

.ng-hide-add-active,
.ng-hide-remove-active {
  transition: 400ms ease all;
}

.ng-hide-add {}
.ng-hide-add.ng-hide-add-active {}
.ng-hide-remove {}
.ng-hide-remove.ng-hide-remove-active {}

.ng-hide {
  opacity: 0;
}


