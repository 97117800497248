.footer {
    color: $zby-blue-dark;
    background: white;
    border-top: 1px solid #DCDCDC;
      width:100%;
      display:flex;
      flex-wrap: wrap;
      position: relative;
      .container_large {
            display: flex;
            justify-content: space-between;
            max-width: 95%;
            width: 100%;
            margin: 0 auto;

            @media screen and (min-width:1800px) {
                  max-width: 80%;
             }

            @media screen and (max-width:1080px) {
                 display: flex;
                 flex-wrap: wrap;
                 max-width: 100%;
                 padding: 0 10px !important;
            }
        }
      .footer-logo {
            display: flex;
            flex-wrap: wrap;

            img {
                  width: 140px;
                  height: 30px;
            }
      }

      .footer-grid {
            display: flex;
            width: 100%;
            padding: 80px 0;

            @media screen and (max-width:1080px) { 
                  flex-wrap: wrap;
                  padding: 70px 0 60px;
            }


            .footer-col {

                  @media screen and (max-width:1080px) {
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 50%;
                        flex: 0 0 50%;
                        margin: 0 0 40px;
                        padding: 0 20px;
                  }

                  &.col1 {
                        width: 30%;
                        @media screen and (max-width:1080px) {
                              max-width: 100%;
                              flex: 0 0 100%;
                              order: 5;
                              margin-bottom: 0;
                        }

                        a {

                              &.phone_number {
                                    color: $zby-blue-dark;
                                    display: block;
                                    &:hover {
                                          color: $zby-purple;
                                    }
                              }
                        }
                  }

                  &.misc-wrap {
                        display: flex;
                        align-items: flex-end;
                        @media screen and (max-width:1080px) {
                              align-items: flex-start;
                        }
                  }

                  a {
                        &:hover {
                              color: $zby-purple;
                        }
                  }

                  &.footer-logo {
                        @media screen and (max-width:1080px) {
                              display: flex;
                              max-width: 100%;
                              flex: 0 0 100%;
                              justify-content: center;
                              align-items: center;
                              text-align: center;
                              margin-top: 10px;
                              order:2;
                        }
                  }



                  .widget_media_image {
                        img {
                              width: 146px;
                              height: 60px;
                        }
                  }

                

                  .gallery {
                        margin: 0;
                        display: flex;
                        align-items: center;
                  }
                  .gallery-columns-4 .gallery-item {
                        margin:0 !important;
                        padding: 0 10px;
                  }

            }
      }

      .social_media {
            width: 100%;
            position: relative;
            margin-bottom: 0;

            p {
                  font-weight: 400;
                  font-size: 14px !important;
                  line-height: 20px !important;
                  letter-spacing: 0.03em;
                  color: $zby-blue-dark;
                  margin-bottom: 10px;
            }

            .widget {

                  ul {
                        padding: 0;
                        margin-left:0 !important;
                        align-items: center;
                        display: flex;

                        li {
                              display: inline-block;
                              list-style: none;
                              margin-right:10px;
                              margin-left:0px !important;
                              padding-left: 0;

                              &:last-child {
                                    margin-right:0;
                              }

                              a {
                                    font-family: "Font Awesome 5 Brands" !important;
                                    color:$zby-blue-dark !important;
                                    font-size: 15px !important;
                                    text-decoration: none !important;
                                    line-height: 24px !important;
                                    text-align: center;
                                    font-weight: normal !important;
                                    width: 26px;
                                    height: 26px;
                                    border-radius: 100%;
                                    border: 1px solid $zby-blue-dark;

                                    &:before {
                                          color: $zby-blue-dark;
                                          font-size: 14px !important;
                                          line-height: 14px !important;
                                    }


                                    &:first-child {
                                          padding-left: 0;
                                    }

                                    &:hover {
                                          opacity: 0.7;
                                          text-decoration: none !important;

                                    }

                              }
                        }
                  }
            }


            .widget {
                  margin: 0;
                  padding-bottom: 0;
            }
      }

      .footer_ratings {
            margin-top:15px;
            p {
                  font-weight: 400;
                  font-size: 14px !important;
                  line-height: 20px !important;
                  letter-spacing: 0.03em;
                  color: $zby-blue-dark;
                  margin-bottom: 5px;

                  &.name {
                        font-weight: 400;
                        font-size: 18px !important;
                        line-height: 18px !important;
                        letter-spacing: 0.01em;
                        margin-top: 20px;
                  }
            }
      }

}


.footer-widget {

      .widget {
            margin-top:0;
            padding:0;
            margin-bottom:40px;
            width:100%;
            @media screen and (max-width:1080px) {
                  margin-bottom: 30px;
            }

            &:last-child {
                  margin-bottom: 0 !important;
            }

            p, a {
                  color: $zby-blue-dark;
                  font-weight: 400 !important;
                  font-size: 16px !important;
                  line-height: 22px !important;
                  letter-spacing: 0.02em;
                  margin: 0 !important;
            }

            a {
                  color: $zby-purple;
                  padding: 0;
            }

            ul {
                  padding: 0;
                  margin-left: 0;

                  li  {
                        list-style: none;
                        padding:0;
                        margin-bottom:10px;
                        margin-left: 0;
                        a {
                              color: $zby-blue-dark;
                              display: block;
                              margin-bottom: 0;
                              font-weight: normal !important;
                              font-size: 16px !important;
                              line-height: 22px !important;

                              &:hover {
                                    color: $zby-purple;
                              }
                        }
                  }
            }
      }
      .widget-title {
            color: $zby-blue-dark !important;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 22px !important;
            text-transform: capitalize;
            letter-spacing: 0.02em;
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 20px;

            &::before {
                  background: $zby-blue-dark;
                  content: "" !important;
                  display: block !important;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 2px;
                  width: 20px;
            }

            @include breakpoint(xs) { 
                  margin-bottom: 20px;
            }


            a:hover {
                  color: $zby-purple;
                  text-decoration: underline !important;
            }

      }

      .copyright {
            margin-top:60px;
            @media screen and (max-width:1080px) { 
                  margin-top:30px;
            }

            p,span,a{
                  font-size: 12px !important;
                  line-height: 18px !important;
                  letter-spacing: 0.03em;
            }
      }

}


.footer_bottom {
      border-top: 1px solid #DCDCDC;
      padding: 60px 0;

      @media screen and (max-width:1080px) { 
            padding: 40px 0;
            max-width: 90%;
            margin:0 auto
      }
      @media screen and (max-width:1080px) {
            max-width: 85%;
      }

      .container_large {
            @media screen and (max-width:1080px) { 
                  padding:0 !important;
            }
      }
      
      .footer-disclosure{
            max-width: 45%;
            @media screen and (max-width:1080px) {
                  max-width: 100%;
            }
      }

      .widget {
            margin:0;
            padding:0;

            p {
                  font-weight: 400;
                  font-size: 12px !important;
                  line-height: 18px !important;
                  letter-spacing: 0.03em;
                  color: #4D4D4D;
                  margin-bottom:0 !important;
            }
      }
}


