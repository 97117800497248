//
//  Pages
//  -----------------------------------------

.page-home {
	padding-top: 0;
}

.page-support {

}

.page-container {
	padding-top: 70px;
	min-height: 70vh;

	h1 {
		font-size: 2em;
		font-weight: 500;
		margin-bottom: 1.5em;
		margin-top: 1.5em;
	}

	h3 {
		font-size: 1.2em;
		font-weight: normal;
		display: block;
		margin: 1.5em 0 0.4em;
		color: $zby-blue-dark;

		@include breakpoint($screen-sm) {
			font-size: 1.5em;
		}
	}

	p, ol li, ul li {
		font-size: 16px;
		font-weight: normal;
	}

	ol, ul {
		li {
			line-height: 1.5;
			margin-bottom: 0.4em;
			margin-left: 1em;
		}
	}
	ol.alpha {
		li{
			list-style:lower-alpha;
		}
	}
	p {
		line-height: 1.5;
	}
}

.get-started-container {
	h1 {
		margin: 0 0 0.6em 0;
		font-size: 25px;
		text-align: left;
		display: block;
		flex: 1 1 100%;

		@include breakpoint($screen-md) {
			// display: none;
		}
	}
}

.is-partner-page {
	.site-footer, .language-switch{
		display: none;
	}

	.external-links{
		color: #505050;
		pointer-events: none;
			cursor: default;
	}

	#oracle-chat {
		display: none;
	}
}

.centered-text {
	text-align: center;
}

.full-width-table {
	width: 100%;
}

.column-35-percent {
	width: 35%;
}

.column-65-percent {
	width: 65%;
}


