//  
//  SVG Art
//  -----------------------------------------

.svg-wrapper-tent {
	position: relative;
	z-index: 2;
	height: 0;
	padding: 0 0 percentage(400/810);
	margin-bottom: -1px;

	.svg-tent {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-tent-lady {
	position: relative;
	z-index: 2;
	height: 0;
	padding: 0 0 percentage(209/91);

	.svg-tent-lady {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}


.svg-wrapper-fire {
	position: relative;
	z-index: 2;
	display: none;

	@include breakpoint($screen-lg) {
		display: block;
	}

	.svg-fire {
		margin-bottom: -15px;
		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-bunkbeds {
	position: relative;
	z-index: 2;
	// width: 95%;
	height: 0;
	padding: 0 0 percentage(452/575);

	.svg-bunkbeds {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-kitchen {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 0;
	padding: 0 0 percentage(389/748);
	// margin-top: 8%;

	// @include breakpoint($screen-sm) {
	// 	margin-top: 20%;
	// }

	// @include breakpoint($screen-md) {
	// 	margin-top: 22%;
	// }

	// @include breakpoint($screen-lg) {
	// 	margin-top: 22%;
	// }

	.svg-kitchen {
		width: 100%;
		height: 100%;
		position: absolute;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-kitchen-man {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 0;
	padding: 0 0 percentage(379/171);

	.svg-kitchen-man {
		width: 100%;
		height: 100%;
		position: absolute;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-table {
	position: relative;
	display: none;
	height: 0;
	padding: 0 0 percentage(418/244);
	margin-bottom: -4px;

	@include breakpoint($screen-lg) {
		display: block;
	}

	.svg-table {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-line {
	position: relative;
	height: 0;
	padding: 0 0 percentage(101/221);

	.svg-line {
		width: 100%;
		height: 100%;

		svg {
			width: 3px;
			height: 100px;
		}
	}
}

.svg-wrapper-backpacks {
	position: relative;
	height: 0;
	padding: 0 0 percentage(249/242);
	display: none;

	@include breakpoint($screen-lg) {
		display: block;
	}

	.svg-backpacks {
		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.hand-wrap {
	position: absolute;
	left: -15.7303371%;
	top: 17.2727273%;
	width: 131.4606742%;
	z-index: -1;
}

.svg-wrapper-hand {
	position: relative;
	height: 0;
	width: 100%;
	padding: 0 0 percentage(351/304);

	.svg-hand {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.svg-wrapper-group {
	width: 100%;
	position: relative;
	height: 0;
	padding: 0 0 percentage(135/219);

	.svg-group {
		position: absolute;
		width: 100%;
		height: 100%;

		svg {
			height: 100%;
			width: 100%;
		}
	}
}