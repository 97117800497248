//
//  Big or small
//  -----------------------------------------

.section-big-or-small {
    overflow: hidden;
}

.col-buildings {
    @include column(6, 6, 12, 12);
}

.col-buildings-copy {
    @include column(6, 6, 12, 12);
}

@-webkit-keyframes moveclouds {
    0% {margin-left: 0px;}
    50% {margin-left: random(100) + px;}
    100% {margin-left: 0px;}
}

.scene-container {
    position: relative;
    padding: 0 2em;

    @include breakpoint($screen-sm) {
        padding: 0 2em 0 0;
    }
}

%cloud-transition {
    transition: left 800ms $easeOutQuart, opacity 500ms ease;
}

.sky-container {
    position: absolute;
    top: 0;
    right: -20%;
    // display: none;
    width: 160%;
    padding-bottom: percentage(471.09/641.57);
    margin-left: -40%;

    @include breakpoint($screen-md) {
        top: -20%;
        bottom: 50%;
        display: block;
    }

    @include breakpoint($screen-md) {
        // display: block;
    }

    &.active {
        .cloud-1 {
            left: 20%;
            opacity: 1;
            @extend %cloud-transition;
            transition-delay: 0;

            @include breakpoint($screen-sm) {
                left: 55%;
            }
        }
        .cloud-2 {
            left: 55%;
            opacity: 1;
            @extend %cloud-transition;
            transition-delay: 100ms;

            @include breakpoint($screen-sm) {
                left: 10%;
            }

        }
        .cloud-3 {
            left: 70%;
            opacity: 1;
            @extend %cloud-transition;
            transition-delay: 300ms;

            @include breakpoint($screen-sm) {
                left: 60%;
            }
        }
    }
}


.sky {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    @extend %gradient-blue;
    z-index: -1;
}

.cloud {
    height: 27px;
    width: 82.5px;
    position: absolute;
    background-image: url('[base]/assets/images/cloud.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint($screen-sm) {
        height: 54px;
        width: 165px;
    }

    &.cloud-1 {
        top: 15%;
        left: 50%;
        opacity: 0;
        @extend %cloud-transition;
    }

    &.cloud-2 {
        top: 25%;
        left: 50%;
        opacity: 0;
        @extend %cloud-transition;
    }

    &.cloud-3 {
        top: 50%;
        left: 50%;
        opacity: 0;
        @extend %cloud-transition;
    }
}



.svg-wrapper-buildings {
    width: 100%;
}

.svg-buildings {
    position: relative;
    padding-bottom: percentage(363 / 391);

    svg, img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

// Copy
.copy-big-or-small {
    width: 100%;
    text-align: center;

    @include breakpoint($screen-md) {
        text-align: left;
        max-width: 20rem;
    }
}
