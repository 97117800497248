//  
//  Code
//  -----------------------------------------


$font-family-monospace: Menlo, Courier;
$code-font-size:              85% !default;
$code-padding-x:              .3rem !default;
$code-padding-y:              .1rem !default;
$code-color:                  #bd4147 !default;
$code-bg:                     #f5f5f5 !default;

$kbd-color:                   #fff !default;
$kbd-bg:                      #333 !default;

$pre-bg:                      #f5f5f5 !default;
$pre-color:                   $zby-gray-darker !default;
$pre-border-color:            #ccc !default;
$pre-scrollable-max-height:   340px !default;

code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

code {
  padding: $code-padding-y $code-padding-x;
  font-size: $code-font-size;
  color: $code-color;
  background-color: $code-bg;
  border-radius: 2px;
}

kbd {
  padding: $code-padding-y $code-padding-x;
  font-size: $code-font-size;
  color: $kbd-color;
  background-color: $kbd-bg;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: $code-font-size;
  color: $pre-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0;
  }
}

.banner {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: darken(#eee, 5%) darken(#eee, 5%) darken(#eee, 12%) darken(#eee, 5%);
    padding-top: 30px;
    padding-bottom: 70px;
    position: relative;
    margin-bottom: 40px;
    border-radius: 3px;

    a {
        margin: auto;
        display: block;
        max-width: 100%;
    }

    .code {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #f5f5f5;
        padding: 10px 5px;
        font-size: 14px;
        width: 100%;
        text-align: center;
        color: lighten($zby-gray-darker, 10%);
        border: 0;
        font-family: $font-family-monospace;
        font-size: 80%;
        border-top: 1px solid darken(#f5f5f5, 4%);
        line-height: 1.3;
    }

    .banner-preface {
        font-size: 13px;
        color: #888;
        text-align: center;
        display: block;
        margin: 0 0 25px;
    }
}