//
//  Main Style Imports
//  -----------------------------------------

// Vendor
@import "vendor/index";

// Utilities
@import "utils/mixins/index";
@import "utils/variables";
@import "utils/utilities";

// Base
@import "base/reset";
@import "base/typography";
@import "base/angular";
@import "base/animations";
@import "base/forms";
@import "base/validation";
@import "base/fonts";

// Components
@import "components/alert";
@import "components/buttons";
// @import "components/banners";
@import "components/carousel";
@import "components/copy";
@import "components/code";
@import "components/icons";
@import "components/logo";
@import "components/nav";
@import "components/navbar";
@import "components/scroll-scene";
@import "components/section";
@import "components/sky";
@import "components/svg";
@import "components/video";
@import "components/compare-tool";
@import "components/tabs";

// Layout
@import "layout/pages";
@import "layout/grid";
@import "layout/section-footer";
@import "layout/section";
@import "layout/section-anytime-anywhere";
@import "layout/section-big-or-small";
@import "layout/section-grow-customer-base";
@import "layout/section-help-you";
@import "layout/section-how-it-works";
@import "layout/section-intro-customer";
@import "layout/section-intro-retailer";
@import "layout/section-logos";
@import "layout/section-video";
@import "layout/section-customer-scenes";

// Pages
@import "pages/page-login";
@import "pages/page-faq";
@import "pages/page-get-started";
@import "pages/page-404";
@import "pages/page-terms";

body {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 16px;
}

* {
    box-sizing: border-box;

    &:before,
	&:after {
	    box-sizing: border-box;
	}
}

.section-2 {
    position: relative;
}

.component-section {
    margin: 0 0 2rem 0;
}

#lc_chat_layout #lc_chat_header {
    border-bottom-color: transparent;
    border-radius: 3px;
}

#banner-zibby-html {
    margin: auto auto;
}

