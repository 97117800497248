//  
//  Terms page
//  -----------------------------------------

.list-mega {
	padding: 0;
	margin: 0 0 20px;

	.list-mega-header {
		color: $zby-blue-dark;
	}

	> li {
		list-style-type: upper-roman;
	}
}