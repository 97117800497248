//
//  Navbar
//  -----------------------------------------
//

.navbar-container {
    position: absolute;
    top: 0;
    z-index: $zindex-navbar-fixed;
    display: block;
    width: 100%;
    // background: white;
    // border-bottom: 1px solid #f2f2f2;
    transition: top 200ms ease;
    min-height: 64px;

    &.home-navbar,
    &.home-navbar.open {
        transition: top 200ms ease, background 150ms ease-in-out;
    }

    @include breakpoint($screen-md) {
        background: none;
        border-bottom: none;
    }

    &:after {
        display: table;
        clear: both;
        content: ' ';
    }

    &.navbar-fixed {
        position: fixed;
        top: 0;
        padding-top: 0;
        transition: top 200ms ease;
        transition: 200ms transform ease;

        @include breakpoint($screen-md) {
            // only keep in initial spot if on desktop
            position: absolute;
            background-color: rgba(#f6f6f7, 0);
        }

        &.unattached,
        &.show-dark {
            position: fixed;
            background-color: rgba(#ffffff, 1);
            // background-image: linear-gradient(#F6F6F7, darken(#f6f6f7, 1%));
            border-bottom: 1px solid #eee;

            .logo-nav a {
                background-image: url('[base]/assets/images/logo-black.png');
            }

            @include breakpoint($screen-md) {
                transform: translate(0, -68px);
                opacity: 0;

                .logo a {
                    background-image: url('[base]/assets/images/logo-white.png');
                }
            }
        }

        &.visible,
        &.show-dark {

            @include breakpoint($screen-md) {
                opacity: 1;
                transform: translate(0, 0);
                transition: 200ms transform ease;

                .logo a {
                    background-image: url('[base]/assets/images/logo-black.png');
                }
            }
        }

        &.show-dark {
            background-color: white;
            background-image: none;
        }
    }
}


.navbar {
    position: relative;
    padding: 0.2rem 1rem;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .collapse.pull-right {
        padding-top: 3px;
    }
}

.navbar-toggle {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: 0;
    padding: 0 15px;
    height: 64px;
    float: right;
    cursor: pointer;

    @include breakpoint($screen-md) {
        display: none;
        visibility: hidden;
    }

    -webkit-tap-highlight-color: rgba(0,0,0,0.2);
    -webkit-tap-highlight-color: transparent; /* For some Androids */

    &:focus {
        outline: 0;
    }
}

.default [id^="nav-icon"] span {
    background: $zby-purple;
}

[id^="nav-icon"] {
    width: 30px;
    height: 26px;
    position: relative;
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    cursor: pointer;
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        border-radius: 1px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .15s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 8px;
        }
        &:nth-child(4) {
            top: 16px;
        }
    }
    &.open span {
        &:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
        &:nth-child(2) {
            transform: rotate(45deg);
        }
        &:nth-child(3) {
            transform: rotate(-45deg);
        }
        &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
}

#navbar-sticky {
}

.mobile-divider {
    display: block;
    width: 100%;
    position: relative;

    @include breakpoint($screen-md) {
        display: none;
    }
}
