//  
//  Nav
//  -----------------------------------------

.section {
	padding: 3em 0;

	@include breakpoint($screen-sm) {
		padding: 6em 0;
	}
}

.copy-section {
    flex-direction: row;
    align-items: center;

    &.align-top {
        align-items: flex-start;
    }
}
