//  
//  Customer scenes
//  -----------------------------------------

$zby-customer-room-bg: linear-gradient(0deg, #F0F4E2 0%, #FDF5E4 69%);;
$zby-customer-dirt: #CEA480;
$zby-customer-kitchen-floor: #1E2128;
$zby-customer-carpet: #967456;

$debug: false;

.section-customer-scenes {
	background: $zby-customer-room-bg;
	position: relative;

	&:after {
		position: relative;
		display: block;
		content: "";
		clear: both;
	}
}

// $debug: true;

.nav-customer-scenes {
	padding: 1em 0;
}

.carousel-wrapper {
	overflow-x: hidden;
	width: 100%;
	float: left;
	display: block;
	position: relative;
	overflow-y: hidden;
	padding-bottom: 3em;

	&:after {
		position: absolute;
		content: "";
		display: block;
		left: 0;
		right: 0;
		bottom: 0;
		height: 3em;
		transition: opacity 200ms ease-in-out 200ms;
	}

	.gradient {
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		right: 0;
		bottom: 0;
		content: "";
		@extend %gradient-dusk;
		opacity: 0;
		transition: opacity 200ms ease 200ms, top 200ms ease 200ms;
		z-index: 0;
	}

	.gradient-kitchen {
	}

	&.active-scene-1 {
		background: $zby-customer-room-bg;
		transition: all 200ms ease-in-out;

		&:after {
			background-color: $zby-customer-carpet;
		}

		.scene-nav .active {
			background: $zby-blue-dark;

			&:hover {
				background: darken($zby-blue-dark, 8%);
			}
		}
	}

	&.active-scene-2 {
		background: $zby-customer-room-bg;
		transition: all 200ms ease-in-out;

		&:after {
			background-color: $zby-customer-dirt;
		}

		.gradient {
			opacity: 1;
			transition: opacity 200ms ease 400ms, top 200ms ease;
			top: 0;
		}

		.scene-nav .active {
			background: $zby-orange;

			&:hover {
				background: darken($zby-orange, 8%);
			}
		}
	}

	&.active-scene-3 {
		background: #E4F8FF;
		transition: all 200ms ease-in-out;

		&:after {
			background-color: $zby-customer-kitchen-floor;
		}

		.scene-nav .active {
			background: $zby-purple;

			&:hover {
				background: darken($zby-purple, 8%);
			}
		}
	}
}

// Individual scenes
.customer-scenes-wrapper {
	width: 300%;

	.active-scene-1 &{
		margin-left: 0;
	}

	.active-scene-2 &{
		margin-left: -100%;
	}

	.active-scene-3 &{
		margin-left: -200%;
	}
}

%scenes-transition {
	transition: all 200ms ease 200ms;
}

%start {
	transform: translate(0, 10px)
}

%end {
	transform: translate(0,0)
}

.customer-scene {
	width: 33.33333%;
	float: left;
	position: relative;

	&.customer-scene-1 {
		.svg-backpacks {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		.svg-bunkbeds,
		.img-bunkbeds {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		&.active {
			.svg-bunkbeds,
			.img-bunkbeds {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
				
			}
			.svg-backpacks {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
				transition-delay: 400ms;
			}

			&.leaving {
				.svg-bunkbeds,
				.svg-backpacks {
					opacity: 0;
					@extend %scenes-transition;
					transform: translate(0, -10px);
				}
			}
		}
	}

	&.customer-scene-2 {
		.svg-tent {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		.svg-tent-lady {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		.svg-fire {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		&.active {
			.svg-tent {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
				transition-delay: 600ms;
			}

			.svg-tent-lady {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
			}

			.svg-fire {
				transition-delay: 400ms;
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
			}

			&.leaving {
				.svg-tent,
				.svg-fire {
					opacity: 0;
					@extend %scenes-transition;
					transform: translate(0, -10px);
				}
			}
		}
	}

	&.customer-scene-3 {
		.svg-table {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		.svg-kitchen,
		.img-kitchen {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		.svg-kitchen-man {
			opacity: 0;
			@extend %start;
			@extend %scenes-transition;
		}

		&.active {
			.svg-table {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
				transition-delay: 400ms;
			}
			.svg-kitchen,
			.img-kitchen {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
				transition-delay: 700ms;
			}

			.svg-kitchen-man {
				opacity: 1;
				@extend %end;
				@extend %scenes-transition;
			}

			&.leaving {
				.svg-table,
				.svg-kitchen {
					opacity: 0;
					@extend %scenes-transition;
					transform: translate(0, -10px);
				}
			}
		}
	}
}

@keyframes fadeInText {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// #table-Group-11-Copy {
// 	display: none;
// }

@keyframes fadeInTextSpan {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOutTextSpan {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.active {
	.col-trees-copy,
	.col-room-copy,
	.col-kitchen-copy {
		h2 span {
			opacity: 0;
			animation: fadeInTextSpan 200ms ease 300ms forwards;
		}
	}
}

.col-trees-copy,
.col-room-copy,
.col-kitchen-copy {
	@include debug('class');
	@include column(12, 12, 10, 10);
	@include columns-before(0, 0, 1, 1);
	text-align: center;
	font-size: 1rem;
	margin-bottom: 40px;
	
	@include breakpoint($screen-md) {
		text-align: center;
		font-size: 1.3rem;
	}

	h2 {
		font-size: 1.5em;
		margin: 1em 0;
		line-height: 1.2;
		position: relative;
		z-index: 100;

		span {
			display: block;
			font-weight: 600;
			color: $zby-orange;
			margin-top:0.2em;
			animation: fadeOutTextSpan 200ms ease 300ms forwards;

			@include breakpoint($screen-sm) {
				margin-top: 0.8em;
			}

			@include breakpoint($screen-lg) {
				display: inline-block;
				margin-top: 0;
			}
		}
	}

	p {
		font-size: 0.9em;
		margin-bottom: 80px;

		@include breakpoint($screen-md) {
			margin: 20px 0;
		}
	}


}


.img-bunkbeds {
	margin-top: 0.8em;
	@include breakpoint($screen-sm) {
		margin-top: 0.8em;
	}
}

.cloud-a,
.cloud-b,
.cloud-c {
	opacity: 0.6;
}

.cloud-a {
	left: 90%;
	top: 30%;
	@extend %start;
	@extend %scenes-transition;
	opacity: 0;

	.active & {
		@extend %end;
		@extend %scenes-transition;
		opacity: 1;
		transition-delay: 400ms;
	}
}

.cloud-b {
	top: 20%;
	left: 60%;
	@extend %start;
	@extend %scenes-transition;
	opacity: 0;

	.active & {
		@extend %end;
		@extend %scenes-transition;
		opacity: 1;
		transition-delay: 800ms;
	}
}

.cloud-c {
	left: 20%;
	top: 45%;
	@extend %start;
	@extend %scenes-transition;
	opacity: 0;

	.active & {
		@extend %end;
		@extend %scenes-transition;
		opacity: 1;
		transition-delay: 600ms;
	}
}

.img-painting-right {
	
}

.img-painting-left {

}

.img-painting-kitchen {
	position: absolute;
	width: 160px;
	top: 42.2492401%;
	display: none;
	@extend %start;
	@extend %scenes-transition;
	opacity: 0;

	@include breakpoint($screen-sm) {
		display: block;
	}

	.active & {
		@extend %end;
		@extend %scenes-transition;
		opacity: 1;
		transition-delay: 600ms;
	}
}

.customer-scene-1 {
	.container {
		margin-bottom: -1em;
	}
}

.col-fire {
	@include debug('class');
	@include column(3, 3, 12, 12);
}

.col-tent {
	@include debug('class');
	@include column(9, 10, 10, 10);
	@include columns-before(0, 1, 1, 1);
}

.col-backpacks {
	@include debug('class');
	@include column(3, 3, 12, 12);
	align-self: center;
	margin-top: -em;
}

.col-bunkbeds {
	@include debug('class');
	@include column(8, 10, 10, 10);
	@include columns-before(1, 1, 1, 1);
}

.col-table {
	@include debug('class');
	@include column(3, 3, 12, 12);
}

.col-kitchen {
	position: relative;
	@include debug('class');
	@include column(8, 10, 10, 10);
	@include columns-before(1, 1, 1, 1);
}

.scene-nav {

	position: relative;
	z-index: 100;
	@include column(12, 12, 12, 12);
	@include columns-before(0, 0, 0, 0);
	width: 100%;
	margin: 0;
	overflow: visible;
	padding: 0;
	text-align: center;
	padding-top: 2em;

	@include breakpoint($screen-sm) {
		padding-top: 4em;
	}

	@include breakpoint($screen-md) {
		justify-content: flex-start;
	}

	> li {
		background: #E0E2E3;
		height: 1em;
		width: 1em;
		border-radius: 50%;
		margin: 0 10px;
		list-style: none;
		cursor: pointer;
		display: inline-block;

		&:hover {
			background: darken(#E0E2E3, 8%);
		}
	}
}

.row-scene {
	align-items: flex-end;
}

.tent-wrap {
	height: 0;
	position: relative;
	width: 100%;
	padding: 0 0 percentage(429/545);

	@include breakpoint($screen-lg) {
		padding: 0 0 percentage(476/686);
	}

	> div:first-child {
		margin-bottom: -1px;
		position: absolute;
		width: 100%;
		bottom: 0;
	}
}

.tent-lady {
	position: absolute;
	width: 11.2068966%;
	left: 46.0591133%;
	bottom: -3%;
}

.kitchen-wrap {
	height: 0;
	position: relative;
	width: 100%;
	padding: 0 0 percentage(476/608);

	> div:first-child {
		position: absolute;
		width: 100%;
		bottom: 0;
	}
}

.kitchen-wrap,
.tent-wrap {
	// @include debug('class');
}

.kitchen-man {
	position: absolute;
	right: 29.144385%;
	width: 22.8%;
	z-index: 10;
	bottom: -5%;
}
